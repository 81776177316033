import { useState } from 'react';

//import ApproveTransactions from './ApproveTransactions';
//import AdjustActions from './AdjustActions';
import UseMoney from '../child/UseMoney';
import AddMoney from '../child/AddMoney';



function ChildPiggy(props) {
  
  const [mode, setMode] = useState('HOME');

  const handleSubmit = () => {
    console.log("ChildPiggy -> handleSubmit");
    setMode("HOME");
    props.onSubmit();
  }

  if (!props.child) return null;
  return (
    <>
      {(mode === 'HOME') && 
        <div>
          <div className="landing-button-box">
            <div className="button white-button" onClick={()=> setMode("ADD_MONEY")}>
              ADD MONEY
            </div>
            <div className="button white-button" onClick={()=> setMode("USE_MONEY")}>
              USE MONEY
            </div>
          </div>
        </div>
      }
      {(mode === 'USE_MONEY') && 
        <UseMoney 
          piggy={props.child} 
          onSubmit={handleSubmit} 
          onCancel={() => setMode("HOME")}
        />
      }
      {(mode === 'ADD_MONEY') && 
        <AddMoney 
          piggy={props.child} 
          onSubmit={handleSubmit} 
          onCancel={() => setMode("HOME")}
        />
      }
    </>
  );
}

export default ChildPiggy;


/*

{(mode === 'TRANSACTIONS') && 
        <ApproveTransactions 
          child={props.child} 
          onApprove={handleApprove} 
          onBack={() => setMode("HOME")}
        />
      }

 <div className="button green-button" onClick={()=> setMode("ADJUST_ACTIONS")}>
              EDIT JOBS
            </div>


{(mode === 'ADJUST_ACTIONS') && 
        <AdjustActions 
          child={props.child} 
          onAdjust={() => setMode("HOME")} 
          onCancel={() => setMode("HOME")}
        />
      }

      */


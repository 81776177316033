import { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";

import Header from '../Header';
import Footer from '../Footer';

import { firebaseAuth } from '../firebase/AuthProvider';
import * as ROUTES from "./routes";

function Start() {
  
  const history = useHistory();
  const { handleStart, errors, route } = useContext(firebaseAuth);

  useEffect(() => {
    // check if user is already logged in
    console.log("Start -> Use Effect -> Check Route:", route);
    
    //redirectUser();
    //reroute user to the logged in state
    if (route !== null && route !== ROUTES.LANDING) {
      console.log("Start -> Use Effect -> Rerouting", route);
      history.replace(route);
    }
  }, [history, route]);

  useEffect(() => { 
    console.log("Start -> Use Effect -> Create Anon Parent Account");    
    handleStart();
  }, [handleStart]);
  


  return (
    <> 
      <Header/>
      <div className="content">
        <div className="landing-title">Loading...</div>
        <div className="Error">
          {errors.length > 0 ? errors.map((error,i) => <p key={i+error }>{error}</p> ) : null}
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Start;

import { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";


import Header from '../Header';
import Footer from '../Footer';
import FAQPage from '../FAQPage';
import Donate from '../Donate';

import ChildPiggy from './ChildPiggy';
import ConvertParent from './ConvertParent';
import PiggySettings from './PiggySettings';
import AddChild from './AddChild';
import ParentNav from './ParentNav';
import ApproveTransactions from './ApproveTransactions';


import {globalMethods} from '../firebase/globalMethods';
import {firebaseAuth} from '../firebase/AuthProvider';

import SettingsIcon from '@mui/icons-material/Settings';



function ParentHome() {

  const history = useHistory();

  const { piggy, refreshPiggy, route } = useContext(firebaseAuth);

  const [mode, setMode] = useState('HOME');

  const [children, setChildren] = useState([]);
  const [childPiggy, setChildPiggy] = useState();
  const [piggyIndex, setPiggyIndex] = useState(0);
  const [childApprovals, setChildApprovals] = useState([]);

  const handleSwitchClick = (index) => {
    console.log("handleSwitch");
    setPiggyIndex(index);
  }

  const handleAddChildStart = () => {
    console.log("handleAddChildStart");
    setMode('ADD_PIGGY');
  }

  const handleAddChildPostSubmit = () => {
    console.log("handleAddChildPostSubmit");
    refreshPiggy();
    setPiggyIndex(children.length);
    setMode("HOME");
  }

  const handleSettingsStart = () => {
    console.log("handleSettingsStart");
    setMode("SETTINGS");
  }

  const handleSettingsSave = () => {
    console.log("handleSettingsSave");
    refreshPiggy();
    //setMode("HOME");
  }

  const handleApprovalStart = () => {
    console.log("handleApprovalStart");
    refreshPiggy();
    setMode("APPROVALS");
  }

  const handleApprovalsApprove = () => {
    console.log("handleApprovalsApprove");
    refreshPiggy();
    setMode("HOME");
  }

  const handlePiggySaveStart = () => {
    console.log("handlePiggySaveStart");
    setMode("SAVE");
  }

  const handlePiggySavePostSubmit = () => {
    console.log("handlePiggySavePostSubmit");
    refreshPiggy();
    setMode("HOME");
  }

  const addFamily = () => {
    if (piggy.isStart) 
      { setMode('SAVE') }
    else
      { setMode('ADD_PIGGY') }
  }

  // set the right child, upon load, and child switching
  useEffect(() => {
    console.log("ParentHome -> Use Effect -> SetChild", piggy);
    if (!piggy) {return null;}
    else if (piggy.isStart) {
      console.log("isStart", piggy.children);
      setChildren(piggy.children);
      setChildPiggy(piggy.children[piggyIndex]);
    }
    else {
      console.log("Children References", piggy.children);
      globalMethods.getPiggyChildren(piggy.children, setChildren, setChildPiggy, piggyIndex);
    }
  }, [piggy, piggyIndex]);

  // get Approvals for each child
  useEffect(() => {
    console.log("ParentHome -> Use Effect -> GetApprovals", childPiggy);
    if (piggy && childPiggy && (childPiggy.id || childPiggy.unsaved)) {
      globalMethods.getNewApprovals(piggy, childPiggy, setChildApprovals);
    }
  }, [childPiggy, piggy]);


  // redirect after logout
  useEffect(() => {
    // route is updated after successful login 
    console.log("ParentHome -> Use Effect -> route:", route);
    // reroute user to the logged out state if route changes
    if (route !== null) history.replace(route);
  }, [route, history]);

  

  if (!piggy || piggy.children.length ===0 ) return null;
  //if (piggy.children.length === 0) refreshPiggy();

  return (
    <>
      <div className="parent-content">
        <Header 
          parent={piggy}
          onAddFamily={addFamily}
          onFaq={() => setMode('FAQ')}
          onDonate ={() => setMode('DONATE')}
        />
        {(piggy.isStart && mode !== 'SAVE') && 
          <div className="piggy-nav-save" onClick={handlePiggySaveStart}>
            Save Your Progress
          </div>
        }
      
        {(mode === 'HOME') && 
          <div className="parent-outer">
            <div className="parent-box">
              <div className="parent-home">
                <SettingsIcon className="piggy-settings" onClick={handleSettingsStart}/>

                <div className="piggy-nav-outer">
                  <ParentNav
                    parent={piggy}
                    child={childPiggy} 
                    children={children} 
                    piggyIndex={piggyIndex}
                    onSwitch={handleSwitchClick} 
                    onAdd={handleAddChildStart}
                    onSave={handlePiggySaveStart} 
                    onSettings={handleSettingsStart}
                    childApprovals = {childApprovals}
                    onApproval={handleApprovalStart}
                  />
                </div>
                <div className="action-button-outer">
                  <ChildPiggy 
                    child={childPiggy} 
                    childApprovals = {childApprovals}
                    onSubmit={handleApprovalStart}
                  />
                </div>
              </div>
            </div>
          </div>
        }
         {(mode === 'APPROVALS') &&  
            
            <div className="parent-outer">
              <div className="parent-box">
                <div className="parent-home">
                  <ApproveTransactions 
                    child={childPiggy} 
                    onApprove={handleApprovalsApprove} 
                    onCancel={() => setMode("HOME")}
                  />
                </div>
              </div>
            </div>
          }
          {(mode === 'SAVE') &&  
            <div className="parent-outer">
              <div className="parent-box">
                <div className="parent-home">
                  <ConvertParent 
                    child={childPiggy}
                    onSave={handlePiggySavePostSubmit} 
                    onCancel={() => setMode("HOME")}
                  />
                </div>
              </div>
            </div>
          }
          {(mode === 'SETTINGS') &&  
            <div className="parent-outer">
              <div className="parent-box">
                <div className="parent-home">
                  <PiggySettings 
                    child={childPiggy}
                    onSettingsSave={handleSettingsSave} 
                    onCancel={() => setMode("HOME")}
                  />
                </div>
              </div>
            </div>
          }
          {(mode === 'ADD_PIGGY') &&  
            <div className="parent-outer">
              <div className="parent-box">
                <div className="parent-home">
                  <AddChild 
                    parent={piggy} 
                    children={children}
                    onSubmit={handleAddChildPostSubmit} 
                    onCancel={() => setMode("HOME")}
                  />
                </div>
              </div>
            </div>
          }
          {(mode === 'FAQ') &&  
            <div className="parent-outer">
              <div className="parent-box">
                <div className="parent-home">
                  <FAQPage  
                    onCancel={() => setMode("HOME")}
                  />
                </div>
              </div>
            </div>
          }
          {(mode === 'DONATE') &&  
            <div className="parent-outer">
              <div className="parent-box">
                <div className="parent-home">
                  <Donate 
                    onCancel={() => setMode("HOME")}
                  />
                </div>
              </div>
            </div>
          }
      </div>
      <Footer/>
    </>
  );
}

export default ParentHome;

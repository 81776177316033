import React, { useState } from 'react';
import Parser from "html-react-parser";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ ({ question, answer }) {

  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-question" onClick={() => setIsActive(!isActive)}>
        <div>
          {question}
          {isActive && <div className="accordion-answer">{Parser(answer)}</div>}
        </div>
        {isActive ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
      </div>
    </div>
  );
}

export default FAQ;



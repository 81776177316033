import React, {useState, useEffect, useCallback} from 'react';
import {authMethods} from './authMethods';
//import 'firebase/auth';
//import firebase from 'firebase';
//import {withRouter} from 'react-router-dom';


export const firebaseAuth = React.createContext()

const AuthProvider = (props) => {
  const initState = {email: '', password: '', name: '', confirmPassword: '', code: ''};
  const [inputs, setInputs] = useState(initState);
  const [errors, setErrors] = useState([]);
  const [authUser, setAuthUser] = useState(null);
  const [route, setRoute] = useState(null);
  const [piggy, setPiggy] = useState(null);
  
  useEffect(() =>{
      console.log("AuthProvider useEffect authuser", authUser)
      authMethods.getAuthUser(setAuthUser, setPiggy);
    }, [authUser]);

  const redirectUser = useCallback(() => {
    console.log('AuthProvider redirectUser');
    authMethods.redirectUser(piggy, setRoute);
  }, [piggy]);

  useEffect(() =>{
      console.log("AuthProvider useEffect: piggy", piggy);
      redirectUser();
  }, [piggy, redirectUser]);

  
  const handleStart = () => {
    console.log('AuthProvider handleStart');
    authMethods.start(setErrors, setAuthUser, setRoute, setPiggy);
  }


  const handleJoin = () => {
    console.log('AuthProvider handleJoin');
    authMethods.join(inputs.email, inputs.password, inputs.name, setErrors, setAuthUser, setRoute, setPiggy);
  }
  
  const handleLogin = () => {
    console.log('AuthProvider handleLogin');
    authMethods.login(inputs.email, inputs.password, setErrors, setAuthUser, setRoute);
  }

  const handleLogout = () => {
    console.log('AuthProvider handleLogout');
    authMethods.logout(setErrors, setAuthUser, setPiggy, setRoute);
  }


  const refreshPiggy = () => {
    console.log('AuthProvider refreshPiggy');
    authMethods.refreshPiggy(piggy, setPiggy);
  }

  const convertStartParent = () => {
    console.log('AuthProvider convertStartParent');
    authMethods.convertStartParent(inputs.email, inputs.password, inputs.name, setErrors);
  }

  const handlePasswordReset = () => {
    console.log('AuthProvider handlePasswordReset');
    authMethods.handlePasswordReset(inputs.email, setErrors);
  }

  const updatePasswordFromEmailLink = () => {
    console.log('AuthProvider updatePasswordFromEmailLink');
    authMethods.updatePasswordFromEmailLink(inputs.password, inputs.code, setErrors);
  }


  return (
    <firebaseAuth.Provider
    value={{
      handleJoin,
      handleLogin,
      handleLogout,
      handleStart,
      handlePasswordReset,
      updatePasswordFromEmailLink,
      convertStartParent,
      redirectUser,
      refreshPiggy,
      authUser,
      piggy,
      inputs,
      setInputs,
      errors,
      route,
      //authWasListened,
    }}>
      {props.children}
    </firebaseAuth.Provider>
    
  );
};

export default AuthProvider;
//export default withRouter(AuthProvider);



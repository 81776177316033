import { getAuth, signInAnonymously, EmailAuthProvider, 
  linkWithCredential, createUserWithEmailAndPassword, signInWithEmailAndPassword, 
  onAuthStateChanged, signOut, sendPasswordResetEmail, 
  confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import {globalMethods} from './globalMethods';
import * as ROUTES from "../account/routes";

export const authMethods = {
  
  getAuthUser: (setAuthUser, setPiggy) => {
    console.log("Auth helper: getAuthUser");

    const auth = getAuth();
    // TODO catch error if connection is down
    console.log("Auth helper: getAuthUser: Auth:", auth);



    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log("Auth helper: getAuthUser: Verified signed in user:", uid);
        setAuthUser(uid);
        // set piggy here eventually triggers useEffect in AuthProvider to redirect user
        globalMethods.getPiggy(uid, setPiggy);
      } else {
        console.log("Auth helper: getAuthUser: No logged in user");
      }
    });
  },

  redirectUser: (piggy, setRoute) => {
    console.log("Auth helper: redirectUser", piggy);
    if (piggy) {
      if (piggy.isParent) {
        setRoute(ROUTES.PARENT + "/" + piggy.id);
      } else {
        setRoute(ROUTES.PIGGY + "/" + piggy.id);
      }
    } else {
        setRoute(ROUTES.LANDING);
    }
  },

  refreshPiggy: (piggy, setPiggy) => {
    console.log("Auth helper: refreshPiggy", piggy);
    globalMethods.getPiggy(piggy.id, setPiggy);
  },
  
  login: (email, password, setErrors, setAuthUser, setRoute) => {
    console.log("Auth helper: login");

    // check if child is logging in with a username, and apply the @simplepiggy.com hack
    if (!email.includes("@")) {
      email=email + '@simplepiggy.com';
    }

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      console.log("Auth helper: login successful:", user);
      setAuthUser(user);
      //setPiggy(user);
      setErrors([]);
      //setRoute(ROUTES.LANDING);
    
      // ...
    })
    .catch((error) => {
      switch(error.code) {
          case "auth/invalid-email":
            // should not happen because i check for email
            setErrors(["The User ID or Password is incorrect. Please try again."]);
            break;
          case "auth/user-not-found":
            setErrors(["The User ID or Password is incorrect. Please try again."]);
            break;
          case "auth/wrong-password":
            setErrors(["The User ID or Password is incorrect. Please try again."]);
            break;
          case "auth/network-request-failed":
            setErrors(["Please check your network connection, and try again."]);
            // TODO: later implement offline dev mode
            //setAuthUser({});
            break;
          default:
            setErrors([error.message]);
      }
    });
    
  },

  logout: (setErrors, setAuthUser, setPiggy, setRoute) => {
    console.log("auth helper: log out");
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("auth helper: logout successful");
      setAuthUser(null);
      setPiggy(null);
      setRoute(ROUTES.LANDING);
    }).catch((error) => {
      console.log("auth helper: log out, Error");
      setErrors(prev => ([...prev, error.message]))
    });
    
  },

  join: (email, password, name, setErrors, setAuthUser, setRoute, setPiggy) => {

    console.log("auth helper: join");
    const auth = getAuth();
    if (name === '') {
      setErrors(prev => ([...prev, "Please provide a name."]));
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          setAuthUser(user.uid);
          console.log("auth helper: join successful:", user);
          setErrors([]);
          globalMethods.createParentPiggy(user, name);
          setRoute(ROUTES.PARENT + "/" + user.uid);
        })
        .catch((error) => {
          switch(error.code) {
            case "auth/email-already-in-use":
              setErrors(["This email is already in use.  Please pick a different one."]);
              break;
            default:
              setErrors(prev => ([...prev, error.message]))
          }
        });
    }
  },

  start: (setErrors, setAuthUser, setRoute, setPiggy) => {

    console.log("auth helper: start");
    const auth = getAuth();
    

    let name = 'start_user';
    signInAnonymously(auth)
    .then((userCredential) => {
      // Signed in..
      
      const user = userCredential.user;
      console.log("Auth helper: anon sign in successful:", user);

      setAuthUser(user.uid);
      setErrors([]);
      globalMethods.createStartParentPiggy(user, name, setPiggy);
      setRoute(ROUTES.PARENT + "/" + user.uid);
    })
    .catch((error) => {
      // errors likely need updates
      switch(error.code) {
        case "auth/email-already-in-use":
          setErrors(["This email is already in use.  Please pick a different one."]);
          break;
        default:
          setErrors(prev => ([...prev, error.message]))
      }
    });
    
  },

  convertStartParent: (email, password, name, setErrors) => {

    console.log("auth helper: convertStartParent");
    
    const credential = EmailAuthProvider.credential(email, password);

    const auth = getAuth();
    linkWithCredential(auth.currentUser, credential)
      .then((usercred) => {
        const user = usercred.user;
        console.log("Anonymous account successfully upgraded", user);
        setErrors([]);
        globalMethods.updateStartParent(user, name, email);
      }).catch((error) => {
        console.log("Error upgrading anonymous account", error);
      });
       
  },

  handlePasswordReset: (email, setErrors) => {

    console.log("auth helper: handlePasswordReset");
    setErrors([]);
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        console.log("Password email sent");
      })
      .catch((error) => {
        switch(error.code) {
          case "auth/user-not-found":
            setErrors(["This email is not registered."]);
            break;
          default:
            setErrors(prev => ([...prev, "Error sending reset password"]));
            console.log("Error sending reset password", error.message);
            break;
        }
      });
  },


  updatePasswordFromEmailLink: (newPassword, code, setErrors) => {

      console.log("auth helper: updatePasswordFromEmailLink");
      setErrors([]);
      const auth = getAuth();
     
      confirmPasswordReset(auth, code, newPassword)
        .then(() => {
          // Password reset email sent!
          // ..
          console.log("Password updated via updatePasswordFromEmailLink");
        })
        .catch((error) => {
          switch(error.code) {
            case "auth/invalid-action-code":
              setErrors(["Your request to reset your password has expired or the link has already been used.  Try requesting a new password reset email."]);
              break;
            case "auth/network-request-failed":
              setErrors(["There is an issue with your network.  Please try again."]);
              break;
            default:
              setErrors(prev => ([...prev, "Error updating password"]));
              console.log("Error updating password", error.message);
              break;
          }
        });
    },




}

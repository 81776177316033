import { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";

import Header from '../Header';
import Footer from '../Footer';
import Transactions from './Transactions';
import MakeMoney from './MakeMoney';
import UseMoney from './UseMoney';
//import Birthday from './Birthday';


import {firebaseAuth} from '../firebase/AuthProvider';

// import {authMethods} from './firebase/authMethods';
//import {globalMethods} from './firebase/globalMethods';

//if (user) history.replace("/dashboard");

function PiggyHome() {

  const [mode, setMode] = useState('HOME');
  const {redirectUser, piggy, route} = useContext(firebaseAuth);
  const history = useHistory();

  useEffect(() => {
    // check if user is already logged in
    console.log("PiggyHome use effect - route:", route);
    //see if user needs to be redirected
    redirectUser();
    // reroute user to the logged in state
    if (route !== null) history.replace(route);
    //if (!authUser) return history.replace(route);
  }, [redirectUser, history, route]);

  if (!piggy) return null;
 // if (piggy && piggy.isParent && children.length===0) return null;


  return (
    <>
      <div className="parent-content">
        <Header />
        {(mode === 'HOME') && 
          <div className="parent-outer">
            <div className="parent-box">
              <div className="parent-home">
                <div className="child-nav-outer">
                  <div className="piggy-nav-piggies-outer">
                    <div className="piggy-nav-center-outer">
                      <div className="piggy-nav-center-circle">
                        <span onClick={() => setMode("DETAILS")}>${piggy.bank}</span>
                      </div>
                    </div>
                  </div>
                  <div className="piggy-nav-name">{piggy.name}</div>  
                </div>
              
                <div className="action-button-outer">
                  <div>
                    <div className="landing-button-box">
                      <div className="button white-button" onClick={()=> setMode("MAKE_MONEY")}>
                        COMPLETE JOBS
                      </div>
                      <div className="button white-button" onClick={()=> setMode("USE_MONEY")}>
                        USE MONEY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {(mode === 'DETAILS') && 
          <div className="parent-outer">
            <div className="parent-box">
              <div className="parent-home">
                <Transactions piggyId={piggy.id} onBack={() => setMode("HOME")}/>
              </div>
            </div>
          </div>
        }
        {(mode === 'MAKE_MONEY') && 
          <div className="parent-outer">
            <div className="parent-box">
              <div className="parent-home">
                <MakeMoney 
                  onSubmit={() => setMode("DETAILS")} 
                  onCancel={() => setMode("HOME")}/>
                  </div>
            </div>
          </div>
        }
        {(mode === 'USE_MONEY') && 
          <div className="parent-outer">
            <div className="parent-box">
              <div className="parent-home">
                <div className="child-nav-outer">
                  <div className="piggy-nav-piggies-outer">
                    <div className="piggy-nav-center-outer">
                      <div className="piggy-nav-center-circle">
                        <span onClick={() => setMode("DETAILS")}>${piggy.bank}</span>
                      </div>
                    </div>
                  </div>
                  <div className="piggy-nav-name">{piggy.name}</div>  
                </div>

                <UseMoney 
                  piggy={piggy} 
                  onSubmit={() => setMode("DETAILS")} 
                  onCancel={() => setMode("HOME")}/>
              </div>
            </div>
          </div>
        }
      </div>
        
      <Footer/>
      
    </>
  );
}

export default PiggyHome;
/*

// Birthday
<div className="button green-button" onClick={()=> setMode("BIRTHDAY")}>
                  BIRTHDAY LIST
                </div>

 {(mode === 'BIRTHDAY') && 
        <Birthday 
          piggy={piggy} 
          onCancel={() => setMode("HOME")}/>
      }

      //// end birthday



 <div className="ChildBody">
        {(mode === 'HOME' && !piggy.isParent) && 
          <div> 
            <p className="ChildName">{piggy.name}</p>
            <img src={logo} className="App-logo" alt="logo" />
            <p className="ChildMoney">${piggy.bank}</p>

            <div className = "FormButtons d-grid gap-2">
              <Button size='lg' variant="outline-danger" onClick={() => setMode("DETAILS")}>Details</Button>
            </div>
            <div className = "FormButtons d-grid gap-2">
              <Button size='lg' variant="outline-danger" onClick={() => setMode("MAKE_MONEY")}>Make Money</Button>
            </div>
            <div className = "FormButtons d-grid gap-2">
              <Button size='lg' variant="outline-danger" onClick={() => setMode("USE_MONEY")}>Use Money</Button>
            </div>
          </div>
        } 

{(mode === 'DETAILS') && 
          <Transactions piggyId={piggy.id} onDone={() => setMode("HOME")}/>
        }
        {(mode === 'MAKE_MONEY') && 
          <MakeMoney onSubmit={() => setMode("DETAILS")} onCancel={() => setMode("HOME")}/>
        }
        {(mode === 'USE_MONEY') && 
          <UseMoney 
            piggy={piggy} 
            onSubmit={() => setMode("DETAILS")} 
            onCancel={() => setMode("HOME")}/>
        }
        */

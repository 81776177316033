import ReactGA from "react-ga"
 
 //const TRACKING_ID = "G-9Y2P5MXM54";  GA4 property
const TRACKING_ID = "UA-206589737-1";
ReactGA.initialize(TRACKING_ID);
 
function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ReactGA.initialize(TRACKING_ID, { debug: isDev })
}
 
function sendEvent(payload) {
  console.log("GA: sendEvent", payload);
  ReactGA.event(payload)
}
 
function sendPageview(path) {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

const exportedObject = {
     init,
     sendEvent,
     sendPageview,
};
 
export default exportedObject;


import { initializeApp } from 'firebase/app'
//import firebase from 'firebase/app';
//import { getFirestore } from 'firebase/firestore/lite';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";

//import { getAuth, onAuthStateChanged } from "firebase/auth";


//import 'firebase/storage'
//import 'firebase/firestore';

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  appId: process.env.REACT_APP_PROD_APP_ID,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
};



/*
const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  appId: process.env.REACT_APP_DEV_APP_ID,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
};
*/


 
//const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

//const firebaseApp = initializeApp(prodConfig);
//const db = getFirestore(firebaseApp);

//const firebaseApp = initializeApp(prodConfig);
initializeApp(prodConfig);

const db = getFirestore();
const functions = getFunctions();


// this one works
//export default db;



export  {
   db as default, 
   functions
}


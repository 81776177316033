import { useState, useEffect, useRef } from 'react';
import {globalMethods} from '../firebase/globalMethods';

import PostAddIcon from '@mui/icons-material/PostAdd';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';


import { useReactToPrint } from 'react-to-print';
import PrintableActions from './PrintableActions';



function PiggySettings(props) {

  //const {handleAddChild, inputs, setInputs, errors, route} = useContext(firebaseAuth);
  const initNameState = {name: props.child.name };
  const [nameInputs, setNameInputs] = useState(initNameState);

  const initJobsState = {id: '', actionName: '', value: '' };
  const [jobsInputs, setJobsInputs] = useState(initJobsState);

  const [actions, setActions] = useState([]);


  const [isNameEdit, setNameEdit] = useState(false);
  const [isJobsEdit, setJobsEdit] = useState(false);
  //const [errors, setErrors] = useState([]);
  
  useEffect(() => { 
    console.log("Settings -> Adjust Actions -> Use Effect");
    if (props.child) {
      if (props.child.unsaved) {
        setActions(props.child.actions);
      } else {
        // get latest actions from DB
        globalMethods.getActions(props.child.id, setActions);
      }
    }
  }, [props.child]);

  const startJobsEdit = (id, name, value) => {
    console.log("Adjust Actions: startJobsEdit", actions);
    //setActions(actions.filter(item => item.id !== id));
    setJobsInputs({"id": id, "name": name, "value": value});
    setJobsEdit(true);
  }

  const handleSaveEditAction = (index) => {
    console.log("Adjust Actions: handleSaveEditAction", actions);
    let updatedActions = [...actions];
    updatedActions[index] = jobsInputs;
    setActions(updatedActions);
    //setActions(prev => ({...prev, "id": id}));
    //setActions(actions.filter(item => item.id !== id));
    setJobsEdit(false);
    setJobsInputs(initJobsState);
    globalMethods.setActions(props.child, updatedActions);
  }

  const handleCancelEditAction = () => {
    console.log("Adjust Actions: handleCancelEditAction", actions);
    setJobsEdit(false);
    setJobsInputs(initJobsState);
  }

  const handleRemoveAction = (id) => {
    console.log("Adjust Actions: handleRemove", actions);
    setActions(actions.filter(item => item.id !== id));
    let updatedActions = actions.filter(item => item.id !== id);
    setActions(updatedActions);
    globalMethods.setActions(props.child, updatedActions);
  }

  const handleAddAction = () => {
    console.log("Adjust Actions: handleAdd", actions);
    setJobsEdit(true);
    let newActionId = Math.floor(Math.random() * 100000000);
    setActions((currentState) => [ ...currentState, 
      { 
        id: newActionId,
        name: "New Job",
        value: "1", 
      }
    ]);
    setJobsInputs(prev => ({...prev, "id": newActionId}));

  }
  
  const handleJobsChange = (e) => {
    const {name, value} = e.target
    setJobsInputs(prev => ({...prev, [name]: value}))
  }

  const startNameEdit = () => {
    console.log("Adjust Actions: startNameEdit");
    //setActions(actions.filter(item => item.id !== id));
    setNameEdit(true);
  }

  const handleNameChange = (e) => {
    const {name, value} = e.target
    setNameInputs(prev => ({...prev, [name]: value}))
  }

  const handleSaveEditName = () => {
    console.log("handleSaveEditName");  
    globalMethods.setPiggyName(props.child, nameInputs.name, props.onSettingsSave);
    setNameEdit(false);
    //setNameInputs(initNameState);
  }

  const handleCancelEditName = () => {
    console.log("Adjust Actions: handleCancelEditName");
    setNameEdit(false);
    setNameInputs(initNameState);
  }

  const componentRef = useRef();
  const handlePrintClick = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <CloseIcon className="login-cancel" onClick={() => props.onCancel()}/>

      <div className='profile-settings'>
        <div className="settings-header">{nameInputs.name}'s Profile</div>
        <div className='profile-element'>
          <div className='profile-element-left'>Name</div>
          <div className='profile-element-right'>
              {isNameEdit && 
                    <input name="name" type="text" onChange={handleNameChange} className="jobs-input" placeholder={nameInputs.name} value={nameInputs.name} />}    
              {!isNameEdit && nameInputs.name}
          </div>
          <div className='profile-controls'>
            {!isNameEdit && <EditIcon onClick={() => startNameEdit()}/>}
            {isNameEdit && <CheckIcon onClick={() => handleSaveEditName()}/>}
            {isNameEdit && <ClearIcon onClick={() => handleCancelEditName()}/>}
          </div>
        </div>
        
        <div className='profile-element'>
          <div className='profile-element-left'>Login ID</div>
          {(!props.child.unsaved && props.child?.login ) && 
            <div className='profile-element-right'>
              {
                [
                  props.child.login.toString().slice(0, 2), 
                  props.child.login.toString().slice(2, 4), 
                  props.child.login.toString().slice(4, 6), 
                  props.child.login.toString().slice(6, 8), 
                ].join('-')
              }
            </div>
          }
          {(props.child.unsaved ) && 
            <div className='profile-element-right'>
              <div className='profile-element-na'>First, click 'Save Progress' above.</div>
            </div>
          }
          <div className='profile-controls'/>
        </div>
        
      </div>
        
        

      <div className="job-settings">
        <div className="settings-header">
          {nameInputs.name}'s Jobs
        </div>
        <div className='settings-table'>
          
          {actions.map(({ id, name, value }, index) => {
            return (
                <div className='profile-element' key={index}>
                  <div className='job-element-left'>
                    {isJobsEdit && jobsInputs.id === id && 
                      <input name="name" type="text" onChange={handleJobsChange} className="jobs-input" placeholder={name} value={jobsInputs.name} />}
                    {(!isJobsEdit || jobsInputs.id !== id) && name}
                  </div>
                  <div className='job-element-right'>
                    {isJobsEdit && jobsInputs.id === id && 
                      <input name="value" type="text" onChange={handleJobsChange} className="jobs-input" placeholder={value} value={jobsInputs.value} />}
                    {(!isJobsEdit || jobsInputs.id !== id) && '$'+value}
                  </div>
                  <div className="job-controls">
                    {!isJobsEdit && <EditIcon onClick={() => startJobsEdit(id, name, value)}/>}
                    {!isJobsEdit && <DeleteIcon onClick={() => handleRemoveAction(id)}/>}
                    {isJobsEdit && jobsInputs.id === id && <CheckIcon onClick={() => handleSaveEditAction(index)}/>}
                    {isJobsEdit && jobsInputs.id === id && <ClearIcon onClick={() => handleCancelEditAction()}/>}
                  </div>
                </div>
            );
            })}
        </div>
      </div>
      <div>
        <PostAddIcon className='settings-action' onClick={() => handleAddAction()}/>
        <PrintIcon className='settings-action' onClick={handlePrintClick}/>
      </div>
      <PrintableActions child={props.child} actions={actions} printRef={componentRef} />

     
    </>
  );
}

export default PiggySettings;

/*

<div className="form-title">Name</div>
        <input name="name" type="text" onChange={handleChange} className="form-input" placeholder="First Name" value={inputs.name} />
        <div className="form-button-outer">
          <button className="form-button green-button" onClick={handleSubmit}>Save</button>
          <button className="form-button white-button" onClick={() => props.onCancel()}>Cancel</button>
        </div>

        */

import { useState, useEffect } from 'react';
import GA from '../../analytics/GA';
import { secondaryAppForCreate } from '../../firebase/secondaryAppForCreate';
//import { firebaseAuth } from '../../firebase/AuthProvider';

import CloseIcon from '@material-ui/icons/Close';




function CreateChild(props) {

  //const {handleAddChild, inputs, setInputs, errors, route} = useContext(firebaseAuth);
  //const initState = {email: '', password: '', name: '', parentUid: props.parent.id };
  const initState = {name: '', parentUid: props.parent.id };
  const [inputs, setInputs] = useState(initState);
  const [errors, setErrors] = useState([]);

  //const { getEmail } = useContext(firebaseAuth)
 
  const [mode, setMode] = useState('HOME');


  useEffect(() => {
    console.log("UE: onCreateChild");
    GA.sendEvent({
      category: 'User',
      action: 'onCreateChild'
    });
  }, []);

  const formatLogin = (login) => {
    console.log(login);

    if (login === undefined) {return ""} else {
      const loginString = 
      [
        login.toString().slice(0, 2), 
        login.toString().slice(2, 4), 
        login.toString().slice(4, 6), 
        login.toString().slice(6, 8), 
      ].join('-');
      return loginString; 
    }
  }
  
  const handleSubmit = async (e) => {
    console.log("ADD CHILD: handleSubmit", inputs);
    e.preventDefault();
    
    // create a new piggy 
    //await secondaryAppForCreate.createPiggy(inputs.email+"@simplepiggy.com", inputs.password, inputs.name, inputs.parentUid, setErrors, props.onSubmit);

    //let parentEmail = await getEmail();

    let loginId = Math.floor(Math.random() * 100000000);


    await secondaryAppForCreate.createPiggy(
      loginId+'@simplepiggy.com', 
      loginId, 
      inputs.name, 
      inputs.parentUid, 
      loginId,
      setErrors, 
      props.onSubmit
    );

  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  return (
    <>
      {(mode === 'HOME' && !props.parent.isStart) && 
        <div className="add-child-box">
          <CloseIcon className="login-cancel" onClick={() => props.onCancel()}/>
          <div className="login-outer">
            <div className="user-select-button" onClick={() => setMode("PARENT")}>PARENT</div>
            <div className="user-select-button" onClick={() => setMode("CHILD")}>CHILD</div>
          </div>
        </div>
      }
      {(mode === 'CHILD') && 
        <div className="add-child-box">
          <CloseIcon className="login-cancel" onClick={() => props.onCancel()}/>
          <div className="login-outer">
            <div className="login-title">ADD A CHILD</div>
            <div className="login-input-label">Child's First Name</div>
            <input className="login-input" autoFocus name="name" type="text" onChange={handleChange} placeholder="Enter First Name" value={inputs.name} />
            <div className="login-error-outer">
              {errors.length > 0 ? errors.map((error,i) => 
                <div className="login-error-text" key={i+error }>{error}</div> ) : null
              }
            </div>
            <div className="login-button" onClick={handleSubmit}>
              ADD
            </div>
          </div>
        </div>
      }
      {(mode === 'PARENT') && 
        <div className="add-child-box">
          <CloseIcon className="login-cancel" onClick={() => props.onCancel()}/>
          <div className="login-outer">
            <div className="login-title">COMING SOON</div>
          </div>
        </div>
      }
      
    </>
  );
}

export default CreateChild;

/*
<div className="login-title">ADD A PARENT</div>
            <div>1. Have a parent create an account</div>
            <div>2. Add child, and provide child login ID</div>
            <div>
              {props.children.map(({ name, login }) => (
                <div key={login}>{name} : {formatLogin(login)}</div>
              ))}
            </div>
*/

import { useState, createElement } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Header from '../Header';
import Footer from '../Footer';

import Frame1 from './join/Frame1';
import Frame2 from './join/Frame2';
//import Frame3 from './join/Frame3';
import Frame4 from './join/Frame4';
import CreateAccount from './join/CreateAccount';


function Join() {
  
  const frames = {
    1: Frame1,
    2: Frame2,
    3: Frame4,
    4: CreateAccount
  };

  const [frame, setFrame] = useState(1);
   
  const returnFrame = (currentFrame) => {
    return createElement(frames[frame], {handleContinue : handleContinue});
  }

  const handleContinue = () => {
    console.log("Join : handle Continue, currentFrame", frame);
    setFrame(frame+1);
    //return createElement(frames[frame], {handleContinue : handleContinue});
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Header />
          </Col>
        </Row>
        <Row className="App-body-buyflow justify-content-md-center">
          <Col sm={12} className="d-flex justify-content-center">
            {returnFrame()}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Join;
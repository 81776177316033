import React from "react"
import { useLocation } from "react-router-dom"

import GA from "./GA"

export default function useGoogleAnalytics() {
  const location = useLocation()

  React.useEffect(() => {
    GA.init()
  }, [])

  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    GA.sendPageview(currentPath)
  }, [location])
}

import { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

import Header from '../Header';
//import Footer from '../Footer';
import logo from '../images/piggy_pink.png';


//import {authMethods} from '../firebase/authMethods';
import { firebaseAuth } from '../firebase/AuthProvider';
import * as ROUTES from "./routes";




function LogIn() {
  
  const history = useHistory();
  const {handleLogin, inputs, setInputs, errors, route, handlePasswordReset} = useContext(firebaseAuth)
  
  const [mode, setMode] = useState('HOME');
  const [childInputs, setChildInputs] = useState(
      { num1: '', num2: '', num3: '', num4: ''});
        

  const handleChange = (e) => {
    const {name, value} = e.target
    //console.log(inputs)
    setInputs(prev => ({...prev, [name]: value}));
  }

  const handleChildChange = (e) => {
    const {name, value} = e.target
    //console.log(inputs)
    setChildInputs(prev => ({...prev, [name]: value}));
    let loginId = childInputs.num1+childInputs.num2+childInputs.num3+childInputs.num4;

    // super hackey, because setChildInputs will happen async, and passing it to inputs wont resolve in time
    switch (name) {
      case "num1":
        loginId = value+childInputs.num2+childInputs.num3+childInputs.num4;
        break;
      case "num2":
        loginId = childInputs.num1+value+childInputs.num3+childInputs.num4;
        break;
      case "num3":
        loginId = childInputs.num1+childInputs.num2+value+childInputs.num4;
        break;
      case "num4":
        loginId = childInputs.num1+childInputs.num2+childInputs.num3+value;
        break;
      default:
        break;
    }
    setInputs({'email': loginId + '@simplepiggy.com', 'password': loginId});
  }

  const handleCancel = () => {
    console.log("LOGIN: handleCancel");
    //props.onDone();
    history.push(ROUTES.LANDING);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('Login: handleSubmit')
    //location.pathname='/';
    await handleLogin();
    
    console.log("After Login: ", errors, route)
    //redirectUser();
    //props.history.push('/');
    //history.push(ROUTES.PIGGY);

  }

  const triggerResetEmail = async (e) => {
    e.preventDefault()
    console.log('Login: triggerResetEmail')
    await handlePasswordReset();
    setMode('AFTER_RESET');
    //redirectUser();
    //props.history.push('/');
    //history.push(ROUTES.PIGGY);

  }


  useEffect(() => {
    // route is updated after successful login 
    console.log("Login use effect - route:", route);
    // reroute user to the logged in state
    if (route !== null && route !== '/') history.replace(route);
  }, [route, history]);

  return (
    <>
      
        <Header/>
        <div className="login-content">
          {(mode === 'HOME') && 
            <div className="login-box">
              <CloseIcon className="login-cancel" onClick={handleCancel}/>
              <div className="login-outer">
                <div className="user-select-button" onClick={() => setMode("PARENT")}>PARENT</div>
                <div className="user-select-button" onClick={() => setMode("CHILD")}>CHILD</div>
              </div>
            </div>
          }
          {(mode === 'CHILD') && 
            <div className="login-box">
              <CloseIcon className="login-cancel" onClick={handleCancel}/>
              <div className="login-outer">
                <div className="login-title">LOGIN CODE</div>
                <div className="login-code-outer">
                  <input autoFocus name="num1" type="text" onChange={handleChildChange} className="child-login-text" value={childInputs.num1} maxLength="2"/>
                  <input name="num2" type="text" onChange={handleChildChange} className="child-login-text" value={childInputs.num2} maxLength="2"/>
                  <input name="num3" type="text" onChange={handleChildChange} className="child-login-text" value={childInputs.num3} maxLength="2"/>
                  <input name="num4" type="text" onChange={handleChildChange} className="child-login-text" value={childInputs.num4} maxLength="2"/>
                </div>
                <div className="login-error-outer">
                  {errors.length > 0 ? errors.map((error,i) => 
                    <div className="login-error-text" key={i+error }>{error}</div> ) : null
                  }
                </div>
                <div className="login-button" onClick={handleSubmit}>
                  LOGIN
                </div>
                <div className="forgot-password" onClick={() => setMode("CHILD_RESET")}>Forgot Code?</div>
              </div>
            </div>
          }
          {(mode === 'CHILD_RESET') && 
            <div className="login-box">
              <CloseIcon className="login-cancel" onClick={handleCancel}/>
              <div className="login-outer">
                <div className="login-title">FORGOT CODE?</div>
                
              </div>
            </div>
          }
          {(mode === 'PARENT') && 
            <div className="login-box">
              <CloseIcon className="login-cancel" onClick={handleCancel}/>
              <div className="login-outer">
                <div className="login-title">LOGIN</div>
                <div className="login-input-label">Email</div>
                <input className="login-input" autoFocus name="email" type="email" onChange={handleChange} placeholder="Enter Email" value={inputs.email} />
                <div className="login-input-label">Password</div>
                <input className="login-input" name="password" type="password" onChange={handleChange} placeholder="Enter Password" value={inputs.password} />
                <div className="login-error-outer">
                  {errors.length > 0 ? errors.map((error,i) => 
                    <div className="login-error-text" key={i+error }>{error}</div> ) : null
                  }
                </div>
                <div className="login-button" onClick={handleSubmit}>
                  LOGIN
                </div>
                <div className="forgot-password" onClick={() => setMode("PASSWORD_RESET")}>Forgot Password?</div>
              </div>
            </div>
          }
          {(mode === 'PASSWORD_RESET') && 
            <div className="login-box">
              <CloseIcon className="login-cancel" onClick={handleCancel}/>
              <div className="login-outer">
                <div className="login-title">FORGOT PASSWORD?</div>
                <div className="password-reset-statement">
                  Enter the email address associated with your account, and we’ll email you a link to reset your password.
                </div>
                <div className="login-input-label">Email</div>
                <input className="login-input" autoFocus name="email" type="email" onChange={handleChange} placeholder="Enter Email" value={inputs.email} />
                <div className="login-error-outer">
                  {errors.length > 0 ? errors.map((error,i) => 
                    <div className="login-error-text" key={i+error }>{error}</div> ) : null
                  }
                </div>
                <div className="login-button" onClick={triggerResetEmail}>
                  SEND RESET LINK
                </div>
              </div>
            </div>
          }
          {(mode === 'AFTER_RESET' && errors.length > 0) && 
            <div className="login-box">
              <CloseIcon className="login-cancel" onClick={handleCancel}/>
              <div className="login-outer">
                <div className="login-title">FORGOT PASSWORD?</div>
                <div className="password-reset-statement">
                  Enter the email address associated with your account, and we’ll email you a link to reset your password.
                </div>
                <div className="login-input-label">Email</div>
                <input className="login-input" autoFocus name="email" type="email" onChange={handleChange} placeholder="Enter Email" value={inputs.email} />
                <div className="login-error-outer">
                  {errors.length > 0 ? errors.map((error,i) => 
                    <div className="login-error-text" key={i+error }>{error}</div> ) : null
                  }
                </div>
                <div className="login-button" onClick={triggerResetEmail}>
                  SEND RESET LINK
                </div>
              </div>
            </div>
          }
          {(mode === 'AFTER_RESET' && errors.length === 0) && 
            <div className="login-box">
              <CloseIcon className="login-cancel" onClick={handleCancel}/>
              <div className="login-outer">
                <div className="login-title">EMAIL SENT</div>
                <div className="landing-spinning-logo-outer">
                   <img src={logo} className="spinning-logo" alt="simple piggy" />
                </div>
                <div className="email-sent-statement">
                  Thank you.  We sent you an email with a link to reset your password.
                </div>
                
              </div>
            </div>
          }
        </div>
     
    </>
  );
}

export default LogIn;

/*<div className="login-or">
                  <span className="login-or-text">OR</span>
                </div>
                <div className="login-auth-methods">
                  <div>Google</div>
                </div>
                <div className="login-redirect-link">Need an account? SIGN UP</div>

                */

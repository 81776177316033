
import {useState, useEffect, useContext} from 'react';
import {globalMethods} from '../firebase/globalMethods';

import {firebaseAuth} from '../firebase/AuthProvider';
import CloseIcon from '@material-ui/icons/Close';




function ApproveTransactions(props) {
  const [transactions, setTransactions] = useState([]);
  const { piggy } = useContext(firebaseAuth);

  
  useEffect(() => { 
    if (piggy.isStart) {
      console.log("ApproveTransactions -> isStart", piggy.children);
      setTransactions(piggy.children[0].transactions);
    } else  {
      console.log("Approve Transactions -> Use Effect");
      globalMethods.getTransactions(props.child.id, setTransactions);
    }
  }, [props.child.id, piggy.children, piggy.isStart]);

/*
  const handleBack = () => {
    props.onBack();
  }
  */

  const handleApprove = () => {
    if (transactions.length !== 0 ) {
      globalMethods.approveTransactions(props.child, transactions, props.onApprove);
    }
  }

  const formatDate = (dateStr) => {
    let d = new Date();
    if (dateStr instanceof Date) {
      d = new Date(dateStr);
    } else {
      d = new Date(dateStr.toDate());
    }
    //date.toDate()).toDateString()
    return (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear().toString().slice(-2);
  }

  const pendingStyle = {color: '#777777', 'font-style': 'italic'};
  const approvedStyle = {color: '#777777'};


  return (
    <>
      <CloseIcon className="login-cancel" onClick={() => props.onCancel()}/>

      <div className="form-button-outer">
        <button className="form-button green-button" onClick={handleApprove}>Approve All</button>
      </div>
      <div className="transactions-table">
        <table striped bordered hover style={{color: '#777777'}} >
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th className='column-no-wrap'>$</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(({ id, status, name, type, value, date }, index) => {
            return (
                <tr key={index} style={(status === 'pending') ? pendingStyle : approvedStyle} >
                  <td>{formatDate(date)}</td>
                  <td className='column-pad'>{name}</td>
                  <td className='column-no-wrap'>{(type === 'add' ? '+ $' : '- $') + value}</td>
                  <td>{status}</td>
                </tr>
            );
            })}
          </tbody>
        </table>
      </div>   
    </>
  );
}

export default ApproveTransactions;


function PrintableActions({child, printRef, actions}) {

  return (
    <>

      <div className="print-only" ref={printRef}>
        <div className="print-box">
          <div className="print-header">{child.name}'s Jobs</div>
          <div className="job-settings">
            {actions.map(({ id, name, value }, index) => {
              return (
                  <div className='profile-element' key={index}>
                    <div className='print-job-element-left'>{name}</div>
                    <div className='print-job-element-right'>${value}</div>
                  </div>
              );
              })}
          </div>
          <div className="print-logo">Simple Piggy</div>
        </div>
      </div>
    </>
  );
}

export default PrintableActions;

export const faqData = [
  {
    question: 'Who is Simple Piggy for?',
    answer: `Simple Piggy is best for children ages 6 and up.`
  },
  {
    question: 'How does Simple Piggy work?',
    answer: `Any time you plan to give money to your child, add it in Simple Piggy instead.
      Whenever you give your child cash, take it away in Simple Piggy as well.`
  },
  {
    question: 'How much does Simple Piggy cost?',
    answer: `Simple Piggy is free.  If you are enjoying it, please consider a small <a className="faq-link" href="/donate">Donation</a>.`
  },
  {
    question: 'Is there an app?',
    answer: `No.  But you can use it on your mobile phone on your favorite browster. You only need to login once, and will stay signed in.`
  },
  {
    question: 'How can I make a suggestion, or report a bug?',
    answer: `You can email contact@simplepiggy.com? Thank you!`
  },

];

/*

{
    question: 'Do I need to link a real bank account?',
    answer: `No.  The parent IS the bank.`
  },


Does my child need their own device?
The Kiddie Kredit app will just as effectively whether you have multiple devices or just one, so no, they don’t need their own device. You will each have your own login screens and dashboards that look slightly different from one another.



As an individual family, there is currently no cost to download or use the app. We want families across the country to begin early and help their children get acquainted with the concept of credit. Once our V2 launches, It will transition to a premium app with a monthly subscription. Please contact us for institutional or bulk purchases

The Kiddie Kredit app will just as effectively whether you have multiple devices or just one, so no, they don’t need their own device. You will each have your own login screens and dashboards that look slightly different from one another.

*/
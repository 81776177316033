// TODO: replace this with functions
// TODO: replace this with functions

import { initializeApp } from 'firebase/app';
//import { getAuth, signInAnonymously, createUserWithEmailAndPassword } from "firebase/auth";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { globalMethods } from './globalMethods';


export const secondaryAppForCreate = {
  
  /*
  createAnonPiggy: ( parentUid, setErrors) => {
    
    const prodConfig = {
      apiKey: process.env.REACT_APP_PROD_API_KEY,
      authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROD_PROJECT_ID,
      storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
      appId: process.env.REACT_APP_PROD_APP_ID,
      messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
      measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
    };

    const secondaryApp = initializeApp(prodConfig, "secondary");
    const secondaryAuth = getAuth(secondaryApp);
    
    signInAnonymously(secondaryAuth)
    .then((userCredential) => {
      // Signed in..
      const user = userCredential.user;
      console.log("SecondaryAuth helper: anon piggy successful:", user);
      setErrors([]);
      globalMethods.createAnonChildPiggy(user.uid, parentUid);
    })
    .catch((error) => {
      // errors likely need updates
      switch(error.code) {
        case "auth/email-already-in-use":
          setErrors(["This email is already in use.  Please pick a different one."]);
          break;
        default:
          setErrors(prev => ([...prev, error.message]))
      }
    });
      
  },
  */

  createPiggy: (email, password, name, parentUid, login, setErrors, markDone) => {
    
    const prodConfig = {
      apiKey: process.env.REACT_APP_PROD_API_KEY,
      authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROD_PROJECT_ID,
      storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
      appId: process.env.REACT_APP_PROD_APP_ID,
      messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
      measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
    };

    const secondaryApp = initializeApp(prodConfig, "secondary");
    const secondaryAuth = getAuth(secondaryApp);
    createUserWithEmailAndPassword(secondaryAuth, email, password)
      .then((userCredential) => {
        // Signed in, but should be ignored
        const user = userCredential.user;
        console.log("secondary auth: create piggy successful:", user);
        setErrors([]);
        globalMethods.addChildPiggy(user.uid, name, parentUid, login, markDone);
      })
      .catch((error) => {
        switch(error.code) {
          case "auth/email-already-in-use":
            setErrors(prev => ([...prev, "This username is already in use.  Please pick a different one."]))
            break;
          default:
            setErrors(prev => ([...prev, error.message]))

        }
      });
      
  },

  savePiggy: (email, password, name, parentUid, bank, login, actions, transactions, setErrors, markDone) => {
    
    const prodConfig = {
      apiKey: process.env.REACT_APP_PROD_API_KEY,
      authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROD_PROJECT_ID,
      storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
      appId: process.env.REACT_APP_PROD_APP_ID,
      messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
      measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
    };

    const secondaryApp = initializeApp(prodConfig, "secondary");
    const secondaryAuth = getAuth(secondaryApp);
    createUserWithEmailAndPassword(secondaryAuth, email, password)
      .then((userCredential) => {
        // Signed in, but should be ignored
        const user = userCredential.user;
        console.log("secondary auth: save piggy successful:", user);
        setErrors([]);
        globalMethods.convertChildPiggy(user.uid, name, parentUid, bank, login, actions, transactions, markDone);
      })
      .catch((error) => {
        switch(error.code) {
          case "auth/email-already-in-use":
            setErrors(prev => ([...prev, "This username is already in use.  Please pick a different one."]))
            break;
          default:
            setErrors(prev => ([...prev, error.message]))

        }
      });
      
  },

}

import db from "./firebaseApp";
import { doc, collection, setDoc, addDoc, getDoc, getDocs, writeBatch, orderBy, where, query, updateDoc, arrayUnion } from 'firebase/firestore'

export const globalMethods = {
  // firebase helper methods go here... 
  
  createParentPiggy: async (user, name) => {
    
    await setDoc(doc(db, "users", user.uid), {
      name: name,
      isParent: true,
      children: [],
    });
  },

  createStartParentPiggy: async (user, name, setPiggy) => {
    
    // old:       children: ['_start_child_001'],
    // create child actions and transactions
    let actions = await globalMethods.getDefaultActions();
    let startParentPiggy = {
      name: name,
      isParent: true,
      isStart: true,
      children: [{
          name: 'Peppa',
          bank: 1,
          unsaved: true,
          parents: [user.uid],
          actions: actions,
          login: Math.floor(Math.random() * 100000000),
          transactions: [
            {
              name: 'initial bank',
              value: 1, 
              status: 'approved',
              type: 'add', 
              date: new Date(),
            }],
        }
      ]
    };
    await setDoc(doc(db, "users", user.uid), startParentPiggy);
    //setPiggy(startParentPiggy);
    setPiggy({...startParentPiggy, id: user.uid});
  },

/*
  createAnonChildPiggy: async (userId, parentUid) => {
    
    //create child
    console.log("global: create child", userId, parentUid);
    await setDoc(doc(db, "users", userId), {
      name: 'Peppa',
      bank: 0,
      parents: [parentUid],
    });

    // create child actions
    const actions = await globalMethods.getDefaultActions();
    globalMethods.setActions(userId, actions);

    // update the parent with children
    const parentRef = doc(db, "users", parentUid);
    await updateDoc(parentRef, {
        children: arrayUnion(userId)
      });
    //markDone();


  },
  */

  // update data associated to start piggy
  // for now reset the children to [] to unlink start_child
  updateStartParent: async (user, name, email) => {
  
    await setDoc(doc(db, "users", user.uid), {
      name: name,
      email: email,
      isParent: true,
      isStart: false,
      children: [],
    });
  },

  addChildPiggy: async (userId, name, parentUid, login, markDone) => {
    
    //create child
    console.log("global: add child", userId, name, parentUid);
    await setDoc(doc(db, "users", userId), {
      name: name,
      bank: 0,
      login: login,
      parents: [parentUid],
    });

    // create child actions
    const actions = await globalMethods.getDefaultActions();
    globalMethods.setActions({"id": userId}, actions);

    // update the parent with children
    const parentRef = doc(db, "users", parentUid);
    await updateDoc(parentRef, {
        children: arrayUnion(userId)
      });
    markDone();
  },

  // this method is used to create a doc/db entry from parent account
  // we need to do this so that we can set the password ONLY once the parent provides a password.
  convertChildPiggy: async (userId, name, parentUid, bank, login, actions, transactions, markDone) => {
    
    //create child
    console.log("global: convert child piggy", userId, name, parentUid, bank, actions, transactions);
    await setDoc(doc(db, "users", userId), {
      name: name,
      bank: bank,
      login: login.toString(),
      parents: [parentUid],
    });

    // create child actions and transactions
    //const actions = await globalMethods.getDefaultActions();
    
    //globalMethods.setTransactions(userId, transactions);


    // update the parent with children
    const parentRef = doc(db, "users", parentUid);
    await updateDoc(parentRef, {
        children: arrayUnion(userId)
      });

    //update actions on the piggy (could be a timing condition if the above setDoc doesn't fiinish)
    globalMethods.setActions({"id":userId}, actions);
    globalMethods.setTransactions({"id":userId}, transactions);

    markDone();
  },

  setPiggyName: async (piggy, name, save) => {
    if (piggy?.unsaved) {
      // update piggy on the parent instead
      let tempPiggy = piggy;
      tempPiggy.name = name;
      const piggyRef = doc(db, "users", piggy.parents[0]);
      await updateDoc(piggyRef, { "children": [tempPiggy] });
    }
    else {
      const piggyRef = doc(db, "users", piggy.id);
      await updateDoc(piggyRef, { name: name });
      console.log("Document written with ID: ", piggyRef.id);
    }
    save();
  },

  
  getPiggy: async (piggyUID, setPiggy) => {
    
    const docRef = doc(db, "users", piggyUID);
    const docSnap = await getDoc(docRef);
    

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setPiggy({...docSnap.data(), id: docSnap.id});
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document in getPiggy!");
    }
  },

  getPiggyChildren: async (children, setChildren, setChildPiggy, piggyIndex) => {
    const tempArray = [];
    for (const childUID of children) {
    //children.forEach((childUID) => {
      console.log("getPiggyChildren: child", childUID);
      // could prob re-use getPiggy, but couldn't get timing to work
      //globalMethods.getPiggy(childUID, async (childObj) => {await tempArray.push(childObj)});
      const docRef = doc(db, "users", childUID);
      const docSnap = await getDoc(docRef);
      

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        tempArray.push({...docSnap.data(), id: docSnap.id});
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document in getPiggyChildren!");
      }
    };
    console.log("tempArray: ", tempArray);
    setChildren(tempArray);
    setChildPiggy(tempArray[piggyIndex]);
  },

  /*getStartPiggyChildren: async (children, setChildren, setChildPiggy, piggyIndex) => {
    const tempArray = [];
    for (const startChild of children) {
    //children.forEach((childUID) => {
      console.log("getStartPiggyChildren: child", startChild);
      tempArray.push({...startChild, id: 'temp_child'});  
    };
    console.log("tempArray: ", tempArray);
    setChildren(tempArray);
    setChildPiggy(tempArray[piggyIndex]);
  },*/

  getDefaultActions: async () => {
    
    //const docRef = doc(db, "users", piggyUID);
    const tempArray = [];
    const querySnapshot = await getDocs(collection(db, "defaultActions"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      tempArray.push({...doc.data(), id: doc.id});
    });
    return(tempArray);
  },

  // TODO rework some of this logic
  getPiggyActions: async (piggyUID, setActions) => {
    
    //const docRef = doc(db, "users", piggyUID);
    const tempArray = [];
    const querySnapshot = await getDocs(collection(db, "users", piggyUID, "actions"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      tempArray.push({...doc.data(), 
        id: doc.id, 
        status: 'pending', 
        type: 'add',
        isChosen: false,
        date: new Date()});
      //setActions(oldArray => 
      //  [...oldArray,{...doc.data, id: doc.id, isChosen: false}] );
    });

    setActions(tempArray);

    //      setActions({...docSnap.data(), id: docSnap.id});
  },

  getActions: async (piggyUID, setActions) => {
    
    //const docRef = doc(db, "users", piggyUID);
    const tempArray = [];
    const querySnapshot = await getDocs(collection(db, "users", piggyUID, "actions"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      tempArray.push({...doc.data(), id: doc.id});
    });
    setActions(tempArray);
  },

  setActions: async (piggy, actions) => {
    
    console.log("Set actions", actions);

    if (piggy?.unsaved) {
      // update piggy on the parent instead
      let tempPiggy = piggy;
      tempPiggy.actions = actions;
      const piggyRef = doc(db, "users", piggy.parents[0]);
      await updateDoc(piggyRef, { "children": [tempPiggy] });
    }
    else {

      // Get a new write batch
      const batch = writeBatch(db);

      // first delete all previous actions -> easier than figuring out which ones to update
      // TODO: there is likely a better way to do this, and not from the client
      const toDeleteActions = [];

      const querySnapshot = await getDocs(collection(db, "users", piggy.id, "actions"));
      querySnapshot.forEach((doc) => {
        toDeleteActions.push(doc.id);
      });

      toDeleteActions.forEach((actionId) => {
        console.log("action to delete", actionId);
        const docRef = doc(db, "users", piggy.id, "actions", actionId);
        batch.delete(docRef);

      });

      // add new actions

      actions.forEach((action) => {
        console.log("action", action);
        //var docRef = doc(db, "users", piggyUID, "transactions");
        //const docRef = doc(db, 'users', 'transactions');
        const docRef = doc(collection(db, "users", piggy.id, "actions"));
        batch.set(docRef, action);
      });

      // Commit the batch
      await batch.commit();
     }

  },

  getTransactions: async (piggyUID, setTrasactions) => {
    
    //const docRef = doc(db, "users", piggyUID);
    console.log("getTransactions: ", piggyUID);
    const tempArray = [];
    const q = query(collection(db, "users", piggyUID, "transactions"), orderBy("status", "desc"), orderBy("date", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log("getTransaction: ", doc.id, " => ", doc.data());
      tempArray.push({...doc.data(), id: doc.id});
    });

    setTrasactions(tempArray);
  },

  setTransactions: async (piggy, transactions) => {
    
    console.log("Set transactions", transactions);

    if (piggy?.unsaved) {
      // update piggy on the parent instead
      let tempPiggy = piggy;
      tempPiggy.transactions.push(...transactions);
      const piggyRef = doc(db, "users", piggy.parents[0]);
      await updateDoc(piggyRef, { "children": [tempPiggy] });
    } else {
      // Get a new write batch
      const batch = writeBatch(db);

      transactions.forEach((transaction) => {
        console.log("transaction", transaction);
        //var docRef = doc(db, "users", piggyUID, "transactions");
        //const docRef = doc(db, 'users', 'transactions');
        const docRef = doc(collection(db, "users", piggy.id, "transactions"));


        //var group1Ref = db.collection("chatroom").doc("group1");


        batch.set(docRef, transaction);
      });

      // Commit the batch
      await batch.commit();
    }
  },

  getNewApprovals: async (piggy, childPiggy, setChildApprovals) => {
    
    console.log("getNewApprovals: ", piggy);
    let tempArray = [];

    if (piggy.isStart) {
      // get approvals on the parent instead
      tempArray = childPiggy.transactions.filter(t => t.status === "pending");      
    } else {
      if (childPiggy.id) {
        const q = await query(collection(db, "users", childPiggy.id, "transactions"), where("status", "==", "pending"));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          console.log("getNewApprovals", doc.id, " => ", doc.data());
          tempArray.push({...doc.data(), id: doc.id});
        });
      } 

    }
    setChildApprovals(tempArray);

  },

  approveTransactions: async (piggy, transactions, approved) => {
    
    console.log("Update transactions", transactions);

    var piggyBank = parseFloat(piggy.bank);
    // Get a new write batch
    if (piggy?.unsaved) {
      // update piggy on the parent instead
      let tempTransactions = [];

      piggy.transactions.forEach((transaction) => {
        console.log("transaction", transaction);
        //update transaction status to approved
        if (transaction.status === "pending") {
          //const docRef = doc(collection(db, "users", piggyUID, "transactions"), transaction.id);
          //batch.update(docRef, {"status": "approved"});
          let tmpTransaction = transaction;
          tmpTransaction.status = 'approved';
          tempTransactions.push(tmpTransaction);
          if (transaction.type === 'withdraw') {
            piggyBank -= parseFloat(transaction.value);
          } else {
            piggyBank += parseFloat(transaction.value);
          }
        } else {
          tempTransactions.push(transaction);
        }
      });

      let tempPiggy = piggy;
      tempPiggy.transactions=tempTransactions;
      tempPiggy.bank = piggyBank;
      const piggyRef = doc(db, "users", piggy.parents[0]);
      await updateDoc(piggyRef, { "children": [tempPiggy] });
    } else {

      const batch = writeBatch(db);

      transactions.forEach((transaction) => {
        console.log("transaction", transaction);
        //update transaction status to approved
        if (transaction.status === "pending") {
          const docRef = doc(collection(db, "users", piggy.id, "transactions"), transaction.id);
          batch.update(docRef, {"status": "approved"});
          if (transaction.type === 'withdraw') {
            piggyBank -= parseFloat(transaction.value);
          } else {
            piggyBank += parseFloat(transaction.value);
          }
        }
      });

      // update piggy bank
      console.log("Approve transactions: piggy bank", piggyBank);
      const docRef = doc(db, "users", piggy.id);
      batch.update(docRef, {"bank": piggyBank});

      // Commit the batch
      await batch.commit();
    }
    approved();
  },

  getFeatureRequests: async (setFeatures) => {
    
    //const docRef = doc(db, "users", piggyUID);
    console.log("getFeatureRequests");
    const tempArray = [];
    const querySnapshot = await getDocs(collection(db, "features"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log("feature: ", doc.id, " => ", doc.data());
      tempArray.push({...doc.data(), id: doc.id });
    });
    console.log("getFeatureRequests: ", tempArray);
    setFeatures(tempArray);
  },

  addFeatureRequest: async (feature, setSubmitted, setInputs, initState) => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "features"), feature);
    console.log("Document written with ID: ", docRef.id);
    setInputs(initState);
    setSubmitted(true);
  },
}

import CloseIcon from '@mui/icons-material/Close';
import { faqData } from './utils/faqData';
import FAQ from './FAQ';



function FAQPage (props) {

  return (
    <>
      <CloseIcon className="login-cancel" onClick={() => props.onCancel()}/>
      <div className='faq-page'>
        <div className="faq-title">Common Questions</div>
          <div className="faq-accordion">
            {faqData.map(({ question, answer }) => (
              <FAQ key={question} question={question} answer={answer} />
            ))}
          </div>
      </div>
    </>
  );
}

export default FAQPage;



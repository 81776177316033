import {useContext} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import logo from './images/piggy_pink.png';
import { useHistory } from "react-router-dom";
import {firebaseAuth} from './firebase/AuthProvider';






function Donate (props) {

  const history = useHistory();
  const { route } = useContext(firebaseAuth);



  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    } else
    {
      history.replace(route);
    }
  }

  return (
    <>
      <CloseIcon className="login-cancel" onClick={handleCancel}/>
      <div className="landing-spinning-logo-outer">
         <img src={logo} className="spinning-logo" alt="simple piggy" />
      </div>
      <div className="donate-statement-title">
        Simple Piggy is free to use
      </div>
      <div className="donate-statement">
        Please consider a small donation to show your support
      </div>
      <a className="donate-button" href="https://buy.stripe.com/5kA6qJ7XW1ff09O8ww">
          DONATE
      </a>
    </>
  );
}

export default Donate;



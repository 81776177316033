import { useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import useSound from 'use-sound';

import Header from './Header';
import Footer from './Footer';
import FAQ from './FAQ';
import { faqData } from './utils/faqData';
import logo from './images/piggy_pink.png';

import pigWhee from './sounds/whee.wav';

import { firebaseAuth } from './firebase/AuthProvider';
import * as ROUTES from "./account/routes";

import GA from './analytics/GA'


function Landing() {


  const history = useHistory();
  const { route, redirectUser } = useContext(firebaseAuth);

  // redirect user from landing if user is logged in
  useEffect(() => {
    // check if user is already logged in
    console.log("Landing use effect: route:", route);
    redirectUser();
    // reroute user to the logged in state
    if (route !== null) history.replace(route);
  }, [redirectUser, history, route]);

  useEffect(() => {
    console.log("UE: onLanding");
    GA.sendEvent({
      category: 'User',
      action: 'onLanding'
    });
  }, []);

  const handleContinue = () => {
    console.log("Landing -> handleContinue");
    history.push(ROUTES.START);
  }

  const handleSignIn = () => {
    console.log("Landing -> handleSignIn");
    history.replace(ROUTES.LOGIN);
  }

  const [whee] = useSound(
    pigWhee,
    { volume: 0.25 }
  );

  return (
    <>
    
      
      <div className="content">
        <Header/>
        <div className="landing-spinning-logo-outer">
           <img src={logo} className="spinning-logo" alt="simple piggy" />
        </div>
        <div className="landing-title">Your Digital Piggy Bank</div>
        <div className="landing-button-outer">
           <div className="landing-button-box">
             <div className="button green-button" onClick={handleContinue} onMouseDown={whee}>
               SNEAK A PEAK
             </div>
             <div className="button white-button" onClick={handleSignIn}>
               SIGN IN
             </div>
           </div>
        </div>
      </div>
      <div className="faq">
        <div className="faq-title">Common Questions</div>
          <div className="faq-accordion">
            {faqData.map(({ question, answer }) => (
              <FAQ key={question} question={question} answer={answer} />
            ))}
          </div>
      </div>
      <Footer/>
   
      
    </>
  );
}

export default Landing;

/*
<Header/>
      <div className="content">
        <div className="landing-spinning-logo-outer">
          <img src={logo} className="spinning-logo" alt="simple piggy" />
        </div>
        <div className="landing-title">Your Digital Piggy Bank</div>
        
        <div className="landing-button-outer">
          <div className="landing-button-box">
            <div className="button green-button" onClick={handleContinue} onMouseDown={whee}>
              SNEAK A PEAK
            </div>
            <div className="button white-button" onClick={handleSignIn}>
              SIGN IN
            </div>
          </div>
        </div>

      </div>
      <Footer/>
       */

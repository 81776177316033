import { useState, useContext, useEffect } from 'react';
import GA from '../analytics/GA';
import { secondaryAppForCreate } from '../firebase/secondaryAppForCreate';
import { firebaseAuth } from '../firebase/AuthProvider';

import CloseIcon from '@material-ui/icons/Close';


function ConvertParent(props) {

  //const {handleAddChild, inputs, setInputs, errors, route} = useContext(firebaseAuth);
  //const initState = {email: '', password: '', name: '', parentUid: props.parent.id };
  //const [inputs, setInputs] = useState(initState);
  const [secondaryErrors, setSecErrors] = useState([]);

  const { convertStartParent, inputs, setInputs, piggy, errors } = useContext(firebaseAuth);




  useEffect(() => {
    console.log("UE: onConvertParent");
    GA.sendEvent({
      category: 'User',
      action: 'onConvertParent'
    });
  }, []);
  
  const handleSubmit = async (e) => {
    console.log("CONVERT PARENT: handleSubmit", inputs);
    e.preventDefault();

    // convert anon parent to email based user
    await convertStartParent();

    // create a new piggy 
    await secondaryAppForCreate.savePiggy(
      props.child.login+'@simplepiggy.com', 
      props.child.login, 
      props.child.name, 
      piggy.id, 
      props.child.bank,
      props.child.login, 
      props.child.actions,
      props.child.transactions, 
      setSecErrors, 
      props.onSave
    );

  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  return (
    <>
      <CloseIcon className="login-cancel" onClick={() => props.onCancel()}/>
      <div className="login-outer">
        <div className="login-title">CREATE ACCOUNT</div>
        <div className="login-input-label">First Name</div>
        <input className="login-input" autoFocus name="name" type="text" onChange={handleChange} placeholder="Enter First Name" value={inputs.name} />
        <div className="login-input-label">Email</div>
        <input className="login-input" name="email" type="email" onChange={handleChange} placeholder="Enter Email" value={inputs.email} />
        <div className="login-input-label">Password</div>
        <input className="login-input" name="password" type="password" onChange={handleChange} placeholder="Enter Password" value={inputs.password} />
        <div className="login-error-outer">
          {errors.length > 0 ? errors.map((error,i) => 
            <div className="login-error-text" key={i+error }>{error}</div> ) : null
          }
        </div>
        <div className="login-error-outer">
          {secondaryErrors.length > 0 ? secondaryErrors.map((error,i) => 
            <div className="login-error-text" key={i+error }>{error}</div> ) : null
          }
        </div>
        <div className="login-button" onClick={handleSubmit}>
          Create
        </div>
      </div>
    </>
  );
}

export default ConvertParent;

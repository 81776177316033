import { useEffect } from 'react';
import GA from '../../analytics/GA';


function Frame2(props) {

  useEffect(() => {
    console.log("UE: onFrame2");
    GA.sendEvent({
      category: 'User',
      action: 'onFrame2'
    });
  }, []);

  return (
    <>
      <div className="Box">
        <h2 className="FlowPhrase">Instill responsibility by letting kids track their own activity</h2>
        <div className="ButtonBox">
          <div className = "FormButtons d-grid gap-2">
            <div className="GreenButton" onClick={()=> props.handleContinue()}>          
                CONTINUE
            </div>  
          </div>
        </div>
      </div>
    </>
  );
}

export default Frame2;

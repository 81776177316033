import { useState } from 'react';
import useSound from 'use-sound';
import { globalMethods } from '../firebase/globalMethods';

import pigWhee from '../sounds/whee.wav';


function UseMoney(props) {
  const [inputs, setInputs] = useState(
      { name: '',
        value: '', 
        status: 'pending',
        type: 'withdraw', 
        date: new Date()});
  const [whee] = useSound(
    pigWhee,
    { volume: 0.25 }
  );

  const handleCancel = () => {
    props.onCancel();
  }

  const handleSubmit = () => {
    console.log("use money handle submit:", inputs);
    // save use money transaction
    globalMethods.setTransactions(props.piggy, [inputs]);
    props.onSubmit();
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }
    
  return (
    <>
      <form onSubmit={handleSubmit} className="form-outer">  
          <input name="value" onChange={handleChange} className="form-input" placeholder="$" value={inputs.value} />
          <input name="name" onChange={handleChange} className="form-input" placeholder="Reason" value={inputs.name} />
          <div className="form-button-outer">
            <button className="form-button green-button" onMouseDown={whee} onClick={handleSubmit}>Use</button>
            <button className="form-button white-button" onClick={handleCancel}>Cancel</button>
          </div>
      </form>
    </>
  );
}

export default UseMoney;
//<p>{props.piggy.name}'s Bank: ${props.piggy.bank}</p>


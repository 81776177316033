import { useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import useSound from 'use-sound';


import { firebaseAuth } from '../../firebase/AuthProvider';
import * as ROUTES from "../routes";
import pigWhee from '../../sounds/whee.wav';

import GA from '../../analytics/GA';


function CreateAccount(props) {

  const history = useHistory();
  const { handleJoin, inputs, setInputs, errors, route } = useContext(firebaseAuth)
  
  const [whee] = useSound(
    pigWhee,
    { volume: 0.25 }
  );

  const handleCancel = () => {
    console.log("JOIN: handleCancel");
    //props.onDone();
    history.push(ROUTES.LANDING);
  }


  const handleSubmit = async (e) => {
    console.log("CreateAccount: handleSubmit", inputs);
    e.preventDefault();

    //wait to signup 
    await handleJoin();
    
  }

  useEffect(() => {
    console.log("UE: onCreateAccount");
    GA.sendEvent({
      category: 'User',
      action: 'onCreateAccount'
    });
  }, []);

  useEffect(() => {
    // check if user is already logged in
    console.log("CreateAccount use effect: route:", route);
    //redirectUser();
    // reroute user to the logged in state
    if (route !== null && route !== ROUTES.LANDING) history.replace(route);
  }, [history, route]);
  

  const handleChange = (e) => {
    const {name, value} = e.target
    setInputs(prev => ({...prev, [name]: value}))
  }

  return (
    <>
      <div className="Box">
        <h2 className="FlowPhrase">Register to get started</h2>
        <div className="FormInputs">      
          
          <input name="name" type="text" onChange={handleChange} className="form-control" placeholder="Enter name" value={inputs.name} />
        </div>
        <div className="FormInputs">      
          
          <input name="email" type="email" onChange={handleChange} className="form-control" placeholder="Enter email" value={inputs.email} />
        </div>
        <div className="FormInputs">  
          
          <input name="password" type="password" onChange={handleChange} className="form-control" placeholder="Enter password" value={inputs.password} />
        </div>
        <div className="ButtonBox">
          <div className = "FormButtons d-grid gap-2">
            <div className="GreenButton" onClick={handleSubmit} onMouseDown={whee}>          
                JOIN
            </div>
          </div>
          <div className = "FormButtons d-grid gap-2">
            <div className="GreenButtonAlt" onClick={handleCancel}>          
                CANCEL
            </div>   
          </div>
        </div>
        
          {errors.length > 0 ? errors.map((error,i) => <p key={i+error } style={{color: 'red'}}>{error}</p> ) : null}
        
      </div>    
    </>
  );
}

export default CreateAccount;
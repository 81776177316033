
import {useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import {firebaseAuth} from '../firebase/AuthProvider';


function Logout() {
  
  const history = useHistory();
  //const [route, setRoute] = useState();

  const {route, handleLogout} = useContext(firebaseAuth);

  useEffect(() => {
    // route is updated after successful login 
    console.log("Logout use effect - route:", route);
    handleLogout();
    // reroute user to the loggedout state
    if (route !== null) history.replace(route);
  }, [handleLogout, route, history]);

  return (
    <>
      <div className="App-body">
        <p>logging out</p>
      </div>
    </>
  );
}

export default Logout;

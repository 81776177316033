import { useState, createElement } from 'react';

/*import Frame1 from './addChild/AddChildF1';
import Frame2 from './addChild/AddChildF2';
import Frame3 from './addChild/AddChildF3';
import Frame4 from './addChild/AddChildF4';*/
import CreateChild from './addChild/CreateChild';


function AddChild(props) {

  // create an account 
  // sign in on your childs device (they'll stay signed in).  For multiple kids <> single device, use chrome profiles.
  // edit actions for each child 
  // print actions 

/*
  const frames = {
    1: Frame1,
    2: Frame2,
    3: Frame3,
    4: Frame4,
    5: CreateChild
  };
*/

  const frames = {
    1: CreateChild
  };

  const [frame, setFrame] = useState(1);

  //const [frame, setFrame] = useState((props.parent.children.length !== 0) ? 5 : 1 );

  const returnFrame = (currentFrame) => {
    return createElement(frames[frame], 
      {
        parent : props.parent, 
        children : props.children,
        onSubmit : props.onSubmit, 
        onCancel: props.onCancel,
        handleContinue : handleContinue,
      });
  }

  const handleContinue = () => {
    console.log("AddChild : handle Continue, currentFrame", frame);
    setFrame(frame+1);
  }

  return (
    <>
      {returnFrame()}
    </>
  );
}

export default AddChild;

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import useGoogleAnalytics from './analytics/AnalyticsHook'; 
import PiggyHome from './child/PiggyHome';
import ParentHome from './parent/ParentHome';
import Landing from './Landing';
import PwReset from './account/PwReset'; 
import LogIn from './account/LogIn'; 
import Logout from './account/Logout'; 
import Join from './account/Join'; 
import Start from './account/Start'; 
import Donate from './Donate'; 

import * as ROUTES from "./account/routes";

import './styles/App.css';

function Routes() {
  useGoogleAnalytics()
 
  return (
    <Switch>
      <Route exact path={ROUTES.LANDING} component={Landing} />
      <Route path={ROUTES.PASSWORD_RESET} component={PwReset} />
      <Route path={ROUTES.LOGIN} component={LogIn} />
      <Route path={ROUTES.LOGOUT} component={Logout} />
      <Route path={ROUTES.JOIN} component={Join} />
      <Route path={ROUTES.START} component={Start} />
      <Route path={ROUTES.PIGGY} component={PiggyHome} />
      <Route path={ROUTES.PARENT} component={ParentHome} />
      <Route path={ROUTES.DONATE} component={Donate} />
    </Switch>
  )
}
 
function App() {

  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;




import {useState, useEffect} from 'react';
import {globalMethods} from '../firebase/globalMethods';

function Transactions(props) {
  const [transactions, setTrasactions] = useState([]);
  
  useEffect(() => { 
    console.log("Child Transactions Use Effect");
    globalMethods.getTransactions(props.piggyId, setTrasactions);
  }, [props.piggyId]);


  const formatDate = (dateStr) => {
    let d = new Date();
    if (dateStr instanceof Date) {
      d = new Date(dateStr);
    } else {
      d = new Date(dateStr.toDate());
    }
    //date.toDate()).toDateString()
    return (d.getMonth() + 1) + '/' + d.getDate() + '/' +  d.getFullYear();
  }


  const pendingStyle = {color: '#777777', 'font-style': 'italic'};
  const approvedStyle = {color: '#777777'};

  return (
    <>
      <div className="form-button-outer">
        <button className="form-button white-button" onClick={() => props.onBack()}>Back</button>
      </div>
       
      <table striped bordered hover style={{color: '#777777'}} >
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Type</th>
            <th>$</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map(({ id, status, name, type, value, date }, index) => {
          return (
              <tr key={index} style={(status === 'pending') ? pendingStyle : approvedStyle} >
                <td>{formatDate(date)}</td>
                <td>{name}</td>
                <td>{type}</td>
                <td>${value}</td>
                <td>{status}</td>
              </tr>
          );
          })}
        </tbody>
      </table>

      <div className="form-button-outer">
        <button className="form-button white-button" onClick={() => props.onBack()}>Back</button>
      </div>
    </>
  );
}

export default Transactions;


function Footer() {

  return (
    <>
      <div className="footer">
        <div className="company-footer">
          <a className="link" href="/Terms.html">Terms</a><span> · </span>
          <a className="link" href="/Privacy.html">Privacy</a><span> · </span>
          <a className="link" href="mailto:contact@simplepiggy.com">Contact</a>
        </div>
        <div className="copyright">© 2022 SimplePiggy, Inc</div>
      </div>
    </>
  );
}

export default Footer;

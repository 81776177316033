import {useContext, useState} from 'react';
import {firebaseAuth} from './firebase/AuthProvider';
import * as ROUTES from "./account/routes";
import { useHistory } from "react-router-dom";

//import MyAccountIcon from '@mui/icons-material/AccountCircle';
import AddFamilyIcon from '@mui/icons-material/SupervisedUserCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import DonateIcon from '@mui/icons-material/Savings';
     

function Header(props) {

  const {authUser} = useContext(firebaseAuth);
  const history = useHistory();

  const getLogoStyle = () => {
    if (authUser !== null) 
      return "logo-left"
    else
      return "logo-center"
  }

  const [checkBoxState, setCheckboxState] = useState(false);

  const addFamily = () => {
    props.onAddFamily();
    setCheckboxState(false);
  }

  const donate = () => {
    props.onDonate();
    setCheckboxState(false);
  }

  const faq = () => {
    props.onFaq();
    setCheckboxState(false);
  }

  return (
    <>
      <div className="header">
        {(authUser !== null) && 
          <div className="menu">
            <input className="checkbox" type="checkbox" name="" id="" 
              checked={checkBoxState} 
              onClick={() => setCheckboxState(!checkBoxState)}
            />
            <div className="line lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <div className="menu-items">
              {props.parent && 
                <div className="menu-item" onClick={addFamily}>
                  <AddFamilyIcon className="menu-icon"/>
                  <div className="menu-text">Add Family</div>
                </div>
              }
              {props.parent &&  
                <div className="menu-item" onClick={faq}>
                  <HelpIcon className="menu-icon"/>
                  <div className="menu-text">FAQ</div>
                </div>
              }
              {props.parent && !props.parent.isStart && props &&
                <div className="menu-item" onClick={donate}>
                  <DonateIcon className="menu-icon"/>
                  <div className="menu-text">Donate</div>
                </div>
              }
              <div className="menu-item" onClick={() => history.replace(ROUTES.LOGOUT)}>
                <LogoutIcon className="menu-icon"/>
                <div className="menu-text">Logout</div>
              </div>
            
            </div>
            <div className="menu-blur"/>
          </div>
        }  
        <div className={getLogoStyle()} >Simple Piggy</div>
      </div>
    </>
  );
}

export default Header;

/*

{props.parent && !props.parent.isStart && props && 
                <div className="menu-item">
                  <MyAccountIcon className="menu-icon"/>
                  <div className="menu-text">My Account</div>
                </div>
              } 

<input class="checkbox" type="checkbox" name="" id="" />
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>

<nav>
              <div class="navbar">
                <div class="container nav-container">
                    <input class="checkbox" type="checkbox" name="" id="" />
                    <div class="hamburger-lines">
                      <span class="line line1"></span>
                      <span class="line line2"></span>
                      <span class="line line3"></span>
                    </div>  
                  <div class="logo">
                    <h1>Navbar</h1>
                  </div>
                  <div class="menu-items">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">about</a></li>
                    <li><a href="#">blogs</a></li>
                    <li><a href="#">portfolio</a></li>
                    <li><a href="#">contact</a></li>
                  </div>
                </div>
              </div>
            </nav>


<div className="logo">Simple Piggy</div>
        {(authUser !== null && props.parent && !props.parent.isStart) && 
          
          <AccountCircleIcon className="header-account"/>

        }  

        {(authUser !== null && props.parent && !props.parent.isStart) && 
          <button className="account-white-button" onClick={() => history.replace(ROUTES.LOGOUT) }>Log Out</button>
        }  

{(authUser !== null && props.parent && !props.parent.isStart) && 
          <button className="account-white-button" onClick={() => history.replace(ROUTES.LOGOUT) }>Log Out</button>
        }  

<button className="account-white-button" onClick={() => history.replace(ROUTES.LOGOUT) }>
            Log Out
          </button>





          */
import { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Header from '../Header';
//import Footer from '../Footer';
import logo from '../images/piggy_pink.png';


//import {authMethods} from '../firebase/authMethods';
import { firebaseAuth } from '../firebase/AuthProvider';
import * as ROUTES from "./routes";




function PwReset() {
  
  const history = useHistory();
  const { inputs, setInputs, errors, route, updatePasswordFromEmailLink } = useContext(firebaseAuth)
  
  const [mode, setMode] = useState('RESET');
  
  useEffect(() => {
    // set code from URL from email  
        console.log("inputs before:", inputs);

    console.log("Login use effect - get code");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeFromUrl = urlParams.get('oobCode');
    setInputs(prev => ({...prev, ["code"]: codeFromUrl}));
    console.log("inputs after:", inputs);
  }, []);

  const handleChange = (e) => {
    const {name, value} = e.target
    //console.log(inputs)
    setInputs(prev => ({...prev, [name]: value}));
  }

  const handleReset = async (e) => {
    e.preventDefault()
    console.log('PwReset: handleReset')
    //location.pathname='/';
    await updatePasswordFromEmailLink();
    console.log("After Reset: ", errors, route)
    //redirectUser();
    //props.history.push('/');
    //history.push(ROUTES.PIGGY);

  }

  const triggerResetEmail = async (e) => {
    e.preventDefault()
    console.log('Login: triggerResetEmail')
    // TOOD: await handlePasswordReset();
    setMode('AFTER_RESET');
    //redirectUser();
    //props.history.push('/');
    //history.push(ROUTES.PIGGY);

  }

/*
  useEffect(() => {
    // route is updated after successful login 
    console.log("Login use effect - route:", route);
    // reroute user to the logged in state
    if (route !== null && route !== '/') history.replace(route);
  }, [route, history]);
  */

  return (
    <>
      
        <Header/>
        <div className="login-content">
          {(mode === 'RESET') && 
            <div className="login-box">
              <div className="login-outer">
                <div className="login-title">RESET PASSWORD</div>
                <input className="login-input" autoFocus name="email" type="password" onChange={handleChange} placeholder="Password" value={inputs.email} />
                <input className="login-input" name="password" type="password" onChange={handleChange} placeholder="Confirm Password" value={inputs.password} />
                <div className="login-error-outer">
                  {errors.length > 0 ? errors.map((error,i) => 
                    <div className="login-error-text" key={i+error }>{error}</div> ) : null
                  }
                </div>
                <div className="login-button" onClick={handleReset}>
                  RESET PASSWORD
                </div>
              </div>
            </div>
          }
          {(mode === 'AFTER_RESET' && errors.length > 0) && 
            <div className="login-box">
              <div className="login-outer">
                <div className="login-title">FORGOT PASSWORD?</div>
                <div className="password-reset-statement">
                  Enter the email address associated with your account, and we’ll email you a link to reset your password.
                </div>
                <div className="login-input-label">Email</div>
                <input className="login-input" autoFocus name="email" type="email" onChange={handleChange} placeholder="Enter Email" value={inputs.email} />
                <div className="login-error-outer">
                  {errors.length > 0 ? errors.map((error,i) => 
                    <div className="login-error-text" key={i+error }>{error}</div> ) : null
                  }
                </div>
                <div className="login-button" onClick={triggerResetEmail}>
                  SEND RESET LINK
                </div>
              </div>
            </div>
          }
          {(mode === 'AFTER_RESET' && errors.length === 0) && 
            <div className="login-box">
              <div className="login-outer">
                <div className="login-title">EMAIL SENT</div>
                <div className="landing-spinning-logo-outer">
                   <img src={logo} className="spinning-logo" alt="simple piggy" />
                </div>
                <div className="email-sent-statement">
                  Thank you.  We sent you an email with a link to reset your password.
                </div>
                
              </div>
            </div>
          }
        </div>
     
    </>
  );
}

export default PwReset;


import {useState, useContext, useEffect} from 'react';
import useSound from 'use-sound';
import {globalMethods} from '../firebase/globalMethods';
import {firebaseAuth} from '../firebase/AuthProvider';

import pigGrunt from '../sounds/pig-grunt.wav';
import pigWhee from '../sounds/whee.wav';




function MakeMoney(props) {
  const {piggy} = useContext(firebaseAuth);

  const [actions, setActions] = useState([]);
  const [earnings, setEarnings] = useState(0);


  const [grunt] = useSound(
    pigGrunt, //'/sounds/pig-grunt.wav',
    { volume: 0.25 }
  );

  const [whee] = useSound(
    pigWhee,
    { volume: 0.25 }
  );

  useEffect(() => {
    
    console.log("Child -> Make Money -> Use Effect -> Get Actions");
    globalMethods.getPiggyActions(piggy.id, setActions);

  }, [piggy.id]);
  
  const setChosen = (id) => {
    let tempArray = Array.from(actions);
    let objIndex = tempArray.findIndex((obj => obj.id === id));
    let tempChosen = tempArray[objIndex].isChosen;

    //Log object to Console.
    console.log("Before update: ", tempChosen, actions);
    tempArray[objIndex].isChosen = !tempChosen;
    if (tempChosen) {
      setEarnings(earnings-parseFloat(tempArray[objIndex].value));
    } else {
      setEarnings(earnings+parseFloat(tempArray[objIndex].value));
    }

    //Update object's name property.
    setActions(tempArray);
    console.log("After update: ", tempChosen, actions);
  }

  const handleCancel = () => {
    props.onCancel();
  }

  const handleSubmit = () => {
    
    // check if user clicked on any actions
    const transactions = [];
    actions.forEach((action) => {
      console.log("action", action);
      if (action.isChosen === true) {
        transactions.push(action);
      }
    });

    // save transactions based on selected actions
    if (transactions.length !== 0 ) {
      globalMethods.setTransactions(piggy, transactions);
    }
    props.onSubmit();
  }

  if (!actions) return null;

    
  return (
    <>
      <div className="form-outer">  
        {actions.map(({ id, name, value, isChosen }, index) => {
        return (
            <div 
              key={index} 
              onClick={() => setChosen(id)}
              onMouseDown={grunt}
              className="child-job-outer"
            >
              <input className="child-job-check" type="checkbox"/>
              <div className="child-job-text">${value}</div>
              <div className="child-job-text">{name}</div>
            </div>
          );
        })}
        <div className="child-earnings">
          <div>Earnings: ${earnings.toFixed(2)}</div>
        </div>
        <div className="form-button-outer">
          <button className="form-button green-button" onMouseDown={whee} onClick={handleSubmit}>Submit</button>
          <button className="form-button white-button" onClick={handleCancel}>Cancel</button>
        </div>
      </div>
    </>
  );
}

export default MakeMoney;

import LeftIcon from '@mui/icons-material/ChevronLeft';
import RightIcon from '@mui/icons-material/ChevronRight';

function ParentNav(props) {

  if (!props.child) return null;

  const getLeftIndex = () => {
    if (props.piggyIndex - 1 < 0) 
      {return props.children.length-1}
    else 
      {return ((props.piggyIndex - 1) % props.children.length)}
  }

  const getRightIndex = () => {
    return ((props.piggyIndex + 1) % props.children.length);
  }

  return (
    <>
      <div className="piggy-nav-piggies-outer">
        {(props.children.length > 1) && 
            <LeftIcon className="next-arrow" onClick={() => props.onSwitch(getLeftIndex)}/>
        }
        <div className="piggy-nav-center-outer">
          <div className="piggy-nav-center-circle">
            <span onClick={() => props.onApproval()}>${Math.ceil(props.child.bank.toFixed(2))}</span>
            {(props.childApprovals.length > 0) && 
              <div className="piggy-nav-approvals-circle" onClick={() => props.onApproval()}>
                <span className="piggy-nav-approvals-text">{props.childApprovals.length}</span>
              </div>
            }
          </div>
        </div>
        {(props.children.length > 1) && 
          <RightIcon className="next-arrow" onClick={() => props.onSwitch(getRightIndex)} />
        }
      </div>
      <div className="piggy-nav-name">{props.child.name}</div>  
    </>
  );
}

export default ParentNav;
